/* Thank you page styles */

.thank-you-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin: 65px 0;
}

@media screen and (min-width: 501px) {
  .thank-you-title {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 500px) {
  .thank-you-title {
    margin-bottom: 40px;
  }
}

.thank-you {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.thank-you.section-text {
  font-size: 20px;
  text-transform: none;
  max-width: 880px;
  font-weight: 800;
  line-height: 1.3;
}

.thank-you.submit-button {
  width: 190px;
  height: 80px;
}

.thank-you.submit-button a:hover {
  color: #fff;
}

.thank-you.section-visit-podcast {
  flex-direction: row;
  align-items: baseline;
  margin: 15px 0 20px;
  flex-wrap: wrap;
}

.thank-you.podcast {
  width: 305px;
  min-height: 305px;
  position: relative;
  justify-content: space-between;
  margin: 0 15px;
}

.thank-you.podcast p {
  font-size: 16px;
  text-transform: none;
  font-weight: 500;
  line-height: 1.3;
  max-width: 288px;
}

.thank-you.podcast img {
  width: 85%;
  margin-bottom: 40px;
}

.thank-you.podcast img.next {
  position: relative;
  bottom: 7px;
}

.thank-you.podcast img.next {
  width: 35%;
  position: relative;
  top: 20px;
}

.thank-you .podcast-preview {
  max-width: 280px;
  padding: 20px;
}

@media screen and (max-width: 840px) {
  .thank-you p {
    font-size: 26px;
  }
}

@media screen and (max-width: 500px) {
  .thank-you p {
    font-size: 22px;
  }
}

@media screen and (max-width: 720px) {
  .thank-you.podcast {
    justify-content: center;
  }

  .thank-you.podcast img:not(.next) {
    width: 85%;
    margin-bottom: 15px;
  }
}
