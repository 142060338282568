/* Login styles */



/* Header */

header .login-header {
    display: flex;
    justify-content: space-evenly;
}

.title--login {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    min-width: 300px;
    margin-top: 65px;
    margin-bottom: 40px;
}

@media screen and (min-width: 400px) {
    .title--login {
        margin-top: 70px;
    }
}

@media screen and (min-width: 1020px) {
    .title--login {
        margin-top: 80px;
    }
}

.title--login h1 {
    width: 100%;
    margin: auto auto;
    padding-top: 25px;
}

@media screen and (min-width: 1000px) {
    .title--login h1 {
        font-size: 80px;
    }
}

@media screen and (min-width: 1400px) {
    .title--login h1 {
        font-size: 92px;
    }
}

/* Main */

.main__login.container {
    position: relative;

    width: 90%;
    max-width: 600px;
    padding: 20px 15px;
}

@media screen and (min-width: 400px) {
    .main__login.container {
        padding: 25px 15px;
    }
}

@media screen and (min-width: 500px) {
    .main__login.container {
        padding: 25px;
    }
}

@media screen and (min-width: 720px) {
    .main__login.container {
        padding: 30px;
    }
}

main .main__login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

main .main__login p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    justify-self: flex-end;

    max-width: 280px;
    margin-bottom: 20px;
}

main .main__login span.welcome {
    font-size: 16px;
    font-weight: 600;
}

.main__login label {
    font-size: 16px;
}

.main__login span {
    font-size: 14px;
    font-weight: 300;
}

.main__login a {
    color: var(--orange);
    text-decoration: underline;
}

@media screen and (min-width: 1100px) {
    main .main__login p {
        font-size: 18px;
    }
    main .main__login span.welcome {
        font-size: 18px;
    }
    .main__login label {
        font-size: 18px;
    }
    .main__login span {
        font-size: 16px;
    }
}

.login-form {
    max-width: 500px;
    min-width: 300px;
    width: 100%;
}

.login-form .input-container {
    display: flex;
    flex-direction: column;

    margin: 10px;
}

.login-form .input-container>div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.login-form input {
    font-size: 16px;

    height: 47px;
    padding: 0 10px;
    margin: 5px 0;
}

.login-form input::placeholder {
    font-style: italic;
}

@media screen and (min-width: 1220px) {
    .login-form input {
        font-size: 18px;
        padding: 0 15px;

        height: 50px;
    }
}

.main__login .submit-button {
    margin: 10px auto;
}

@media screen and (min-width: 1220px) {
    .main__login .submit-button {
        margin: 15px auto;
    }
}

.main__login--sign-up {
    line-height: 18px;
}

/* Footer */

footer.footer--login {
    top: auto;
}

.background-sun.background-sun--login {
    top: 0;
}

.main__login .airpod-right {
    top: -50px;
    right: -100px;
}

.main__login .airpod-left {
    top: 350px;
    left: -100px;
}

@media screen and (max-width: 1020px) {
    .main__login .airpod-right,
    .main__login .airpod-left {
        width: 100px;
    }
    .main__login .airpod-right {
        right: 0;
    }
    .main__login .airpod-left {
        left: 0;
    }
}

@media screen and (max-width: 820px) {
    .main__login .airpod-right,
    .main__login .airpod-left {
        display: none;
    }
}

/* .background__rainbow--image.rainbow--first.login--rainbow {
    top: -300px;
}

@media screen and (min-width: 390px) {
    .background__rainbow--image.rainbow--first.login--rainbow {
        top: -280px;
    }
}

@media screen and (min-width: 720px) {
    .background__rainbow--image.rainbow--first.login--rainbow {
        top: -220px;
    }
}

@media screen and (min-width: 1020px) {
    .background__rainbow--image.rainbow--first.login--rainbow {
        top: -170px;
    }
}

@media screen and (min-width: 1420px) {
    .background__rainbow--image.rainbow--first.login--rainbow {
        top: -140px;
    }
}

@media screen and (min-width: 1921px) {
    .background__rainbow--image.rainbow--first.login--rainbow {
        top: -200px;
    }
}

@media screen and (min-width: 2320px) {
    .background__rainbow--image.rainbow--first.login--rainbow {
        top: -260px;
    }
} */